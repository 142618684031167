import React from "react"
import ImgPolyfill from "gatsby-image/withIEPolyfill"
import LocalizedLink from "../components/localizedLink"

import {FadeInUp} from "../components/animations/fadeInUp"

const Headband = ({ data, img, partners, subscriptionLink, partnersLogo }) => {
    return (
        <div className={"c-headband u-pd-hz-xxl"}>

            <div className="l-grid u-height-full u-overflow-hidden u-border-top u-border-bottom">

                <div className="l-col-12 l-col-6@main u-pd-vt-xxl@main">

                    <div className="c-headband__content">
                        <FadeInUp yOffset={50} delay={100}>
                            <div className="u-flex u-flex-wrap no-wrap-headband u-pd-top-m u-pd-0@main u-mg-bottom-xl">
                                {partners.map((partner, index) => {
                                    let image = partnersLogo.find(item => item.name === partner.image)
                                    return (
                                        <a href={partner.link} key={"headband-partner-" + index} target="_blank" aria-label={partner.name} rel="noopener noreferrer">
                                            <ImgPolyfill
                                                fixed={image.childImageSharp.fixed}
                                                objectFit="contain"
                                                className="u-mg-s u-mg-right-m@main"
                                                alt={partner.name}
                                            />
                                        </a>
                                    )
                                })}
                            </div>
                        </FadeInUp>

                        <FadeInUp yOffset={50} delay={300}>
                            <h1 className="c-h1 u-green80 u-bold u-uppercase u-mg-bottom-xl">{data.title}</h1>
                        </FadeInUp>

                        <FadeInUp yOffset={50} delay={500}>
                            <p className="u-mg-bottom-l">{data.desc}</p>
                        </FadeInUp>

                        <FadeInUp yOffset={50} delay={700}>
                            <a href={subscriptionLink} target="_blank" rel="noopener noreferrer" className="c-btn c-btn--primary u-mg-bottom-xl">
                                <span>{data.cta}</span>
                                <span>{data.cta_hover}</span>
                            </a>
                        </FadeInUp>

                    </div>

                </div>

                <div className="l-col-12 l-col-6@main u-pd-bottom-xl u-pd-bottom-0@main">

                    {/* <div style={{ maxWidth: "500px" }} className="u-mg-hz-auto u-mg-hz-0@main"> */}
                    <div className="u-relative u-height-full">
                        <ImgPolyfill
                            fluid={img}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            style={{ maxWidth: "50vw" }}
                            loading="eager"
                            fadeIn={false}
                            className="u-absolute@main u-top-0 u-block u-bordered u-border-0-top@main u-border-0-bottom@main u-height-full u-width-full u-mg-hz-auto"
                            alt=""
                        />
                    </div>
                    {/* </div> */}
                </div>

            </div>

        </div>

    )
}

export default Headband
