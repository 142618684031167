import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Headband from "../components/headband"
import IconComponent from "../components/icon"
import FourchetteIcon from "../components/svg/fourchette-icon"
import LocalizedLink from "../components/localizedLink"

import { IntersectionObserver } from "../components/utils/intersection-observer"
import { FadeInUp } from "../components/animations/fadeInUp"

class HomePage extends React.Component {

  render() {
    const { data, pageContext: { locale } } = this.props
    const content = data.file.childContentJson.home

    return (
      <Layout {...this.props}>
        <div className={"c-content"}>

          <SEO noTitleTemplate title={content.pageTitle} />

          <Headband
            data={content.hero}
            subscriptionLink={data.file.childContentJson.global.subscriptionLink}
            img={data.headbandImage.childImageSharp.fluid}
            partnersLogo={data.bigPartnersLogo.nodes}
            partners={data.file.childContentJson.global.partners}
          />

          <IntersectionObserver className="u-pd-vt-xxl u-bg-white">
            <div className="l-container">

              <div className="u-pd-hz-xxl u-center">

                <FadeInUp yOffset={50} delay={100}>
                  <h2 className="c-h2 u-uppercase u-green80 u-mg-bottom-m">{content.keypoints.title}</h2>
                </FadeInUp>

                <FadeInUp yOffset={50} delay={200}>
                  <p className="u-mg-bottom-l">{content.keypoints.desc}</p>
                </FadeInUp>

                <FadeInUp yOffset={50} delay={300}>
                {content.keypoints.list.map((brand, jndex) => {
                    return (
                      <div className="l-grid u-mg-bottom-l" key={"keypoint-brand-" + jndex}>
                        <div className="l-col-12">
                          <h3 className="c-h4 u-left">{brand.name}</h3>
                        </div>
                        {brand.list.map((item, index) => {
                          const note = item.note
                            ? <div className={"u-fs-xs u-mg-hz-auto u-mg-top-m"} style={{ maxWidth: "250px" }}>{item.note}</div>
                            : null;

                          return (
                            <div className="l-col-12 l-col-4@main" key={"keypoint-" + index}>
                              <div className={" u-pd-bottom-l u-pd-l@main u-height-full u-bordered@main"} style={index > 0 ? { borderLeft: "none"} : {}}>
                                <div className="u-flex u-flex-center-vt u-flex-center-hz u-mg-hz-auto" style={{ height: "83px", width: "83px"}}>
                                  <IconComponent name={item.icon} color={item.color} />
                                </div>
                                <div className={"u-bold u-fs-m u-" + item.color}>{item.number}</div>
                                <div className={"u-fs-s u-mg-hz-auto"} style={{ maxWidth: "250px" }}>{item.desc}</div>
                                {note}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )
                })}
                </FadeInUp>
              </div>
            </div>

            <div className="l-container-lg">
              <FadeInUp yOffset={50} delay={400}>
                <div className="l-grid u-pd-top-xxl">
                    <div className="l-col-12 l-col-8@main u-flex u-flex-center-vt u-pd-hz-l">
                      <div className="u-green80 u-semibold u-mg-bottom-l u-center u-left@main u-mg-hz-auto u-mg-0@main">{content.keypoints.punchline}</div>
                    </div>
                    <div className="l-col-12 l-col-4@main u-center">
                      <LocalizedLink to={'/programme'} className="c-btn c-btn--green80" style={{ minWidth: "240px" }}>
                        <span>{content.keypoints.cta}</span>
                        <span>{content.keypoints.cta_hover}</span>
                      </LocalizedLink>
                    </div>
                  </div>
              </FadeInUp>
            </div>
          </IntersectionObserver>

          <section className="u-bg-green80 u-border-top">
            <div className="l-container">
              <div className="l-grid u-pd-vt-xxl u-pd-hz-l u-flex-center-vt">
                <div className="l-col-12 l-col-6@main">
                  <div className="u-white u-bold u-center u-right@main unormal u-mg-bottom-l u-mg-0@main" style={{ fontSize: "28px"}}>{content.band.punchline}</div>
                </div>
                <div className="l-col-12 l-col-2@main u-pd-hz-l u-hide u-flex@main u-flex-center-vt">
                  <hr style={{ borderColor: 'white'}}/>
                </div>
                <div className="l-col-12 l-col-4@main u-center u-left@main">
                  <LocalizedLink to={'/accelerateur'} className="c-btn c-btn--primary">
                    <span>{content.band.cta}</span>
                    <span>{content.band.cta_hover}</span>
                  </LocalizedLink>
                </div>
              </div>
            </div>
          </section>


        </div>
      </Layout>
    )
  }
}

export default HomePage

export const basicImageFragment = graphql`
  fragment basicImageFragment on File {
    childImageSharp {
      fluid(maxWidth: 600, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`
export const pageQuery = graphql`
  query ($locale: String) {
    file(relativeDirectory: { eq: $locale }) {
      childContentJson {
        home {
          pageTitle
          band {
            cta
            cta_hover
            punchline
          }
          hero {
            cta
            cta_hover
            desc
            title
          }
          keypoints {
            cta
            cta_hover
            desc
            list {
              for
              name
              list {
                desc
                note
                icon
                number
                color
              }
            }
            punchline
            title
          }
        }
        global {
          subscriptionLink
          header {
            candidate
            lang {
              en
              fr
            }
            navigation {
              accelerator
              english
              blog
              team
              program
              thematic
            }
          }
          footer {
            by
            cgu
            contact
            email
            press
            social {
              facebook
              instagram
              title
              twitter
              linkedin
            }
          }
          partners {
            name
            image
            link
          }
        }
      }
    }
    partnersLogo: allFile(
      filter: { absolutePath: { regex: "//partners//" } }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    },
    bigPartnersLogo: allFile(
      filter: { absolutePath: { regex: "//partners//" } }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(height: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    },
    headbandImage: file(relativePath: { eq: "img/home/illumiam.png" }) { ...basicImageFragment },
  }
`
